<template>
  <el-card shadow="never" style="padding-bottom: 150px">
    <el-form :model="formData" label-width="200px">
      <el-form-item label="发放对象：">
        <el-select v-model="formData.applyUserId" filterable class="form-item" placeholder="请选择发放对象">
          <el-option v-for="item in userList" :key="item.id" :label="item.userFullName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="耗材编码：">
        <el-select v-model="formData.packMaterialCode" class="form-item" filterable placeholder="请选择耗材编码">
          <el-option v-for="item in packMaterialCodeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发放数量：">
        <el-input-number v-model="formData.stockAmount" :min="1" :step="1" step-strictly class="form-item"></el-input-number>
      </el-form-item>
      <el-row>
        <el-col :span="5" style="text-align: right">
          <el-button type="primary" @click="submit" :loading="loading">发放</el-button>
        </el-col>
        <el-col :span="19">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "ConsumableOut",
  components: {MessageComponent},
  data() {
    return {
      formData: {
        applyUserId: '',
        packMaterialCode: '',
        stockAmount: undefined
      },
      packMaterialCodeList: [],
      userList: [],
      loading: false
    }
  },
  created() {
    this.$axios.post('packMaterial/getAllPackMaterial').then(response => {
      let res = response.data;
      if (res.code !== 0) {
        return this.$message.error('获取包材编码数据源失败，' + res.message);
      }
      let list = res.data || [];
      this.packMaterialCodeList = list.filter(item => item.codeType === '20')
      console.log(res.data)
    }, error => {
      this.$message.error('获取包材编码数据源失败，' + error.message);
    })

    this.$axios.get('user/getAllUser').then(response => {
      let res = response.data;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }

      this.userList = res.data || [];
    }, error => {
      return this.$message.error(`获取人员列表失败，${error.message}`);
    })
  },
  methods: {
    submit() {
      this.$refs.commitMessage.clear();
      if (!this.formData.applyUserId) {
        return this.$refs.commitMessage.showError("请选择发放对象");
      }
      if (!this.formData.packMaterialCode) {
        return this.$refs.commitMessage.showError("请选择耗材编码");
      }

      if (!this.formData.stockAmount || this.formData.stockAmount < 1) {
        return this.$refs.commitMessage.showError("请输入发放数量");
      }

      this.loading = true
      this.$axios.post('storeAmount/consumableOut', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        this.$refs.commitMessage.showSuccess('提交成功');
        this.formData.applyUserId = ''
        this.formData.packMaterialCode = ''
        this.formData.stockAmount = undefined
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      })

    }
  }
}
</script>

<style scoped>
.form-item {
  width: 250px;
}
</style>